import React from "react";

import Books from "./books";
import Introduction from "./introduction";
import AuthorBio from "./authorBio";
import Trailers from "./trailers";
import * as api from "../lib/api";
import LatestNews from "./latestNews";

const Content = () => {
  const availableBooks = api.getVisibleBooks();
  const featuredBooks = api.getFeaturedBooks(availableBooks);
  const trailers = api.getTrailers(availableBooks);

  return (
    <div className="contents">
      <Introduction title="Introduction" tag="intro" />
      <Books
        title="Featured Books"
        tag="featured"
        items={featuredBooks}
        hideFilters
      />
      <LatestNews title="Latest News" />
      <AuthorBio title="About Me" tag="about" />
      <Trailers
        title="Book Trailers"
        tag="trailers"
        items={trailers}
        showOnly={4}
      />
      <Books
        title="Fiction Books"
        tag="fiction"
        items={availableBooks}
        genre={"fiction"}
      />
      <Books
        title="Nonfiction Books"
        tag="nonfiction"
        items={availableBooks}
        genre={"nonfiction"}
      />
      <Books
        title="Kindle Singles"
        tag="singles"
        items={availableBooks}
        genre={"single"}
      />
    </div>
  );
};

export default Content;
