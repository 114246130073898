

import appData from "../_data/appData.json";
import { filterByKey, filterByItemKey } from "../lib/filters";
import { EXISTS, NOTEXISTS } from "../lib/constants";

export const getVisibleBooks = () => {
  return filterByKey("hidden", NOTEXISTS, appData.books);
};

export const searchItems = (search, items) => {

 const extractText = (text, keyword, wordCount = 5) => {
   // Split the text into words, including punctuation as separate tokens
    const words = text.split(/\s+/);
    const results = [];

    // Iterate over each word to find partial matches
    for (let i = 0; i < words.length; i++) {
        if (words[i].toLowerCase().includes(keyword.toLowerCase())) {
            // Extract words before and after the match
            const start = Math.max(0, i - wordCount);  // Ensure we don't go below index 0
            const end = Math.min(words.length, i + wordCount + 1);  // Ensure we don't go beyond the text length
            
            // Join the surrounding words into a snippet
            const snippet = words.slice(start, end).join(' ');
            results.push(snippet);
        }
    }
    return results[0];
  }

  if (!search || !items)
    return [];

  let results = [];
  search = search.toLowerCase();
  
  items.forEach(item => {
    if (item.title?.toLowerCase().indexOf(search) >= 0) {
      results.push({book: item, id: item.id, title: item.title,  match: item.title});
    }
    else if(item.description?.toLowerCase().indexOf(search) >= 0) {
      results.push({book: item, id: item.id, title: item.title, match: extractText(item.description, search, 10)});
    }
    else if(item.keywords?.some(k => k.toLowerCase().indexOf(search.toLowerCase()) >= 0)) {
      results.push({book: item, id: item.id, title: item.title, match: item.keywords.join(", ")});
    }
  });

  return results;
}

export const getBooksById = (id) => {
  return filterByKey("id", id, appData.books);
};

export const getBooksByGenre = (genre, items) => {
  return filterByItemKey("genres", genre, items ?? appData.books)
};

export const getBooksByCategory = (category, items) => {
  return filterByItemKey(
      "categories",
      category,
      items ?? appData.books
    );
};

export const getBooksBySeries = (seriesId, items) => {
  return filterByItemKey("series", seriesId, items ?? appData.books);
};

export const getCategoriesByGenre = (genre) => {
  return filterByItemKey("genres", genre, appData.categories)
};

export const getSeriesById = (seriesId) => {
  return filterByKey("id", seriesId, appData.series);
};

export const getSeriesByGenre = (genre) => {
  return filterByItemKey("genres", genre, appData.series)
};

export const getBookItemsBySeriesId = (seriesId) => {
  const visibleBooks = getVisibleBooks();
  return filterByItemKey("series", seriesId, visibleBooks);
};

export const getFeaturedBooks = (items) => {
  return filterByItemKey(
    "categories",
    "featured",
    items
  );
};

export const getTrailers = (items) => {
  return filterByKey("trailerUrl", EXISTS, items);
};




