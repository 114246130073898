
export const isNotNullOrEmpty = (item) => {
  return !!item && item.length > 0;
};

export const convertJsonTextToHtml = (text) => {
  let result = text ?? '';
  result = result.replaceAll("\\n", "<br/>");
  result = result.replaceAll("\\b", "<strong>");
  result = result.replaceAll("//b", "</strong>");
  result = result.replaceAll("\\hr", "<hr>");

  return result;
}

