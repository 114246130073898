import React from "react";

const SubscribeWidget = () => {
  return (
    <div className="subscribe-widget-container">
      <form
        action="https://subscribe.wordpress.com"
        method="post"
        acceptCharset="utf-8"
        data-blog="5252229"
        data-post_access_level="everybody"
        id="subscribe-blog"
      >
        <p className="subscribe-widget-label">
          <small>Subscribe for new posts by email</small>
        </p>

        <input
          className="subscribe-widget-input form-control"
          type="email"
          name="email"
          placeholder="Email address"
          id="subscribe-field"
        />

        <p id="subscribe-submit">
          <input type="hidden" name="action" value="subscribe" />
          <input type="hidden" name="blog_id" value="5252229" />
          <input
            type="hidden"
            name="source"
            value="https://beingrefactored.com/"
          />
          <input type="hidden" name="sub-type" value="widget" />
          <input
            type="hidden"
            name="redirect_fragment"
            value="subscribe-blog"
          />
          <input
            type="hidden"
            id="_wpnonce"
            name="_wpnonce"
            value="8e9a3b2266"
          />
          <button
            type="submit"
            className="btn btn-secondary subscribe-widget-button"
          >
            Subscribe
          </button>
        </p>
      </form>
    </div>
  );
};

export default SubscribeWidget;
