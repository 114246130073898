import React from "react";
import news from "../_data/newsFeed.json";
import { isNotNullOrEmpty, convertJsonTextToHtml } from "../lib/utilities";
import SubscribeWidget from "./subscribeWidget";

const LatestNews = ({ title, tag }) => {
  const renderNewsFeed = () => {
    if (!news) return <></>;

    const sortedNews = news.sort(
      (a, b) => new Date(b.pubDate) - new Date(a.pubDate)
    );
    return (
      <div>
        {sortedNews.map((item) => {
          return (
            <div className="news-item-entry" key={item.title}>
              <strong className="news-item-title">{item.title}</strong>
              <em className="news-item-date">
                <small>{new Date(item.pubDate).toDateString()}</small>
              </em>
              <p
                dangerouslySetInnerHTML={{
                  __html: convertJsonTextToHtml(item.description),
                }}
              />
              {isNotNullOrEmpty(item.contentUrl) && (
                <a
                  title={item.contentUrl}
                  href={item.contentUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.contentUrl}
                </a>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="section">
      <div className="heading" id={tag}>
        <h3>{title}</h3>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-12 col-md-9">{renderNewsFeed()}</div>
          <div className="col-12 col-md-3">
            <SubscribeWidget />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestNews;
