import React from "react";

const Introduction = ({ title, tag }) => {
  return (
    <div className="section">
      <div className="heading introduction" id={tag}>
        <h3>{title}</h3>
      </div>
      <div className="content">
        <div className="row">
          <div className="col">
            <p>
              Welcome to the author website of C.W. Hambleton (Chris Hambleton),
              where I believe in the power of both fact and fiction to unlock
              deeper truths.
              <br />
              <br />
              Whether you're looking for heart-racing thrillers, introspective
              biblical fiction, or insightful nonfiction, my invites you to
              think beyond the ordinary and question the world around you. For a
              more personal look into my thoughts and reflections, I blog at{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://beingrefactored.com"
              >
                BeingRefactored.com
              </a>
              .
            </p>
            <div style={{ textAlign: "center", margin: 30 }}>
              <em>
                Exploring the boundaries of faith, truth, and imagination
                through speculative fiction, political intrigue, and
                thought-provoking nonfiction!
              </em>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
