import React from "react";
import { Link } from "react-router-dom";
import TrailerViewer from "./trailerViewer";
import * as api from "../lib/api";
import { isNotNullOrEmpty } from "../lib/utilities";

const Trailers = ({ title, tag, items, showOnly = 0 }) => {
  const getTrailerItems = () => {
    if (!isNotNullOrEmpty(items)) {
      const availableBooks = api.getVisibleBooks();
      items = api.getTrailers(availableBooks);
    }

    const sortedItems = items.sort(
      (a, b) => new Date(b.pubDate) - new Date(a.pubDate)
    );

    const itemsToShow =
      showOnly > 0 ? sortedItems.slice(0, showOnly) : sortedItems;

    return itemsToShow;
  };

  const trailerItems = getTrailerItems();
  const extraContent = showOnly <= 0;

  return (
    <div className={`section ${extraContent ? "contents" : ""}`}>
      <div className="heading" id={tag}>
        <h3>{title}</h3>
        {!extraContent && <Link to="/trailers/all">View All</Link>}
      </div>
      <div className="content">
        <div className="row">
          {trailerItems.map((item) => (
            <div className="col" key={item.id}>
              <TrailerViewer itemId={item.id} videoId={item.trailerUrl} />
              <div className="trailer-link">
                <Link to={`/book/${item.id}`}>{item.title}</Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Trailers;
