import React from "react";
import { useNavigate } from "react-router-dom";

const NavBackButton = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <div className="nav-back-button">
      <button className="btn btn-link" onClick={handleGoBack}>
        Go Back
      </button>
    </div>
  );
};

export default NavBackButton;
