import React from "react";
import { useParams, Link } from "react-router-dom";
import * as api from "../lib/api";
import NavBackButton from "./navBackButton";
import { isNotNullOrEmpty } from "../lib/utilities";
import Highlighter from "react-highlight-words";

const SearchResults = () => {
  const { query } = useParams();

  const availableBooks = api.getVisibleBooks();
  const searchResults = api.searchItems(query, availableBooks);

  const noResultsFoundMsg = `No results found for "${query}"`;
  const searchResultsMsg = `Search Results for "${query}"`;

  const renderSearchResults = () => {
    if (!isNotNullOrEmpty(searchResults)) return <></>;

    return (
      <>
        {searchResults.map((item) => {
          console.log(item.match);
          return (
            <div key={item.id} style={{ marginBottom: 10 }}>
              <Link to={`/book/${item.id}`}>
                <strong>{item.title}</strong>
              </Link>
              <br />
              <em>
                <Highlighter
                  highlightClassName="YourHighlightClass"
                  searchWords={[query]}
                  autoEscape={true}
                  textToHighlight={item.match ?? ""}
                />
              </em>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className="section">
      <div className="contents">
        {isNotNullOrEmpty(searchResults) ? (
          <h4>{searchResultsMsg}</h4>
        ) : (
          <h5>{noResultsFoundMsg}</h5>
        )}
      </div>
      <NavBackButton />
      <div className="content">
        <div className="row">
          <div className="col">{renderSearchResults()}</div>
        </div>
        <div className="row">
          <div className="col">
            <div className="search-return-home">
              <Link to="/">Return to Home</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResults;
