import React from "react";
import { Link } from "react-router-dom";

import NavBackButton from "./navBackButton";

const NotFound = () => {
  return (
    <div className="section">
      <div className="contents">
        <h4>Not Found</h4>
      </div>
      <NavBackButton />
      <div className="content">
        <div className="row">
          <div className="col">Oops! We couldn't find that page!</div>
        </div>
        <div className="row">
          <div className="col">
            <div className="search-return-home">
              <Link to="/">Return to Home</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
