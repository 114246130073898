export const EXISTS = "EXISTS";
export const NOTEXISTS = "NOTEXISTS";

export const BoxSetNumber = 9999;

/**
ss="visible-xs">
<div className="vid youtube"
  style="margin:5px; background-image:url(./img/trailers/trailer_castors.jpg)"
  onClick="window.open('https://www.youtube.com/watch?v=zfbMC2X6DCQ','mywindow');">
</div>
<div className="vid youtube"
  style="margin:5px; background-image:url(./img/trailers/trailer_ttojt.jpg)"
  onClick="window.open('https://www.youtube.com/watch?v=InnqUizqGdM','mywindow');">
</div>
<div className="vid youtube"
  style="margin:5px; background-image:url(./img/trailers/trailer_haman.jpg)"
  onClick="window.open('https://www.youtube.com/watch?v=TJdKQSZDsG0','mywindow');">
</div>
<div className="vid youtube"
  style="margin:5px; background-image:url(./img/trailers/trailer_cell.jpg)"
  onClick="window.open('https://www.youtube.com/watch?v=fWH17wGfjwI','mywindow');">
</div>
<div className="vid youtube"
  style="margin:5px; background-image:url(./img/trailers/trailer_endeavor.jpg)"
  onClick="window.open('https://www.youtube.com/watch?v=Pt-vJ6BpCko','mywindow');">
</div>
<div className="vid youtube"
  style="margin:5px; background-image:url(./img/trailers/trailer_rich.jpg)"
  onclick="window.open('https://www.youtube.com/playlist?list=PLMnKZskvz5tijXRh52LdpmEZdYjl2qtdi','mywindow');">
</div>
</div> */