import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isNotNullOrEmpty } from './utilities';

const ScrollToTop = () => {
  const location = useLocation();

  const pathname = location.pathname + location.search + location.hash;
  const hashpart = location.hash;

  useEffect(() => {
    if (!isNotNullOrEmpty(hashpart)) {
      window.scrollTo(0, 0);
    }
  }, [pathname, hashpart]);

  return null;
};

export default ScrollToTop;