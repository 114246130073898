import * as constants from './constants';


export const filterByKey = (key, value, items) => {
  if (!key || !value || !items)
    return items || [];

  if (value === constants.EXISTS) {
    return items.filter(x => !!x[key]);
  }

  if (value === constants.NOTEXISTS) {
    return items.filter(x => !x[key]);
  }

  const results = items.filter(x => x[key] === value) || [];
  return results.filter(x => !!x);
}

export const filterByItemKey = (key, value, items) => {
  if (!key || !value || !items)
    return items || [];

  const results = items.filter(x => !!x[key] && x[key].includes(value)) || [];
  return results.filter(x => !!x);
}

