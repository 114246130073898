import React from "react";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className="footer">
      Copyright {year} Chris Hambleton.&nbsp;&nbsp;All Rights Reserved
    </div>
  );
};

export default Footer;
