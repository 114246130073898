import React from "react";
import { Link } from "react-router-dom";

const BookList = ({ items }) => {
  const sortedItems = items.sort(
    (a, b) => new Date(b.pubDate) - new Date(a.pubDate)
  );
  return (
    <div className="row">
      {sortedItems.map((item) => (
        <div className="col" key={item.id}>
          <Link to={`/book/${item.id}`}>
            <div className="book-frame">
              <img
                alt={item.title}
                className="book"
                src={`/assets/books/${item.id}.jpg`}
              />
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default BookList;
