import React, { useState } from "react";
import BookList from "./bookList";
import * as api from "../lib/api";

const Books = ({ title, tag, items, genre, hideFilters = false }) => {
  const [activeCategory, setActiveCategory] = useState();
  const [activeSeries, setActiveSeries] = useState();

  const filterCategory = "category";
  const filterSeries = "series";

  const onFilterChange = (type, itemKey) => {
    if (type === filterCategory) {
      setActiveCategory(itemKey);
      setActiveSeries();
    } else if (type === filterSeries) {
      setActiveCategory();
      setActiveSeries(itemKey);
    } else {
      setActiveCategory();
      setActiveSeries();
    }
  };

  const renderSeries = () => {
    const filtered = api.getSeriesByGenre(genre);
    const results =
      filtered.length > 0
        ? [{ id: undefined, title: "All" }, ...filtered]
        : undefined;

    if (!results || results.length <= 0) return <></>;

    return (
      <div className="row d-none d-md-block">
        <div className="col">
          <strong>Series</strong>
          <ul className="nav book-items">
            {results.map((item) => (
              <li
                className="nav-item"
                key={item.id + item.title}
                onClick={() => onFilterChange(filterSeries, item.id)}
              >
                <a
                  className={
                    activeSeries === item.id ? "nav-link active" : "nav-link"
                  }
                  href="javascript:void(0)"
                >
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  const renderCategories = () => {
    const filtered = api.getCategoriesByGenre(genre);
    const results =
      filtered.length > 0
        ? [{ key: undefined, label: "All" }, ...filtered]
        : undefined;
    if (!results || results.length <= 0) return <></>;

    return (
      <div className="row d-none d-md-block">
        <div className="col">
          <strong>Categories</strong>
          <ul className="nav book-items">
            {results.map((item) => (
              <li
                className="nav-item"
                key={item.key + item.label}
                onClick={() => onFilterChange(filterCategory, item.key)}
              >
                <a
                  className={
                    activeCategory === item.key ? "nav-link active" : "nav-link"
                  }
                  href="javascript:void(0)"
                >
                  {item.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  let filteredItems = api.getBooksByGenre(genre, items);
  if (activeSeries)
    filteredItems = api.getBooksBySeries(activeSeries, filteredItems);
  if (activeCategory)
    filteredItems = api.getBooksByCategory(activeCategory, filteredItems);

  return (
    <div className="section">
      <div className="heading" id={tag}>
        <h3>{title}</h3>
      </div>
      <div className="content">
        {!hideFilters && (
          <>
            {renderCategories()}
            {renderSeries()}
          </>
        )}
        <BookList items={filteredItems} />
      </div>
    </div>
  );
};

export default Books;
