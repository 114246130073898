import React from "react";

const AuthorBio = ({ title, tag, content }) => {
  return (
    <div className="section">
      <div className="heading" id={tag}>
        <h3>{title}</h3>
      </div>
      <div className="content">
        <div style={{ marginLeft: 10, float: "right" }}>
          <img
            alt="author-bio"
            className="author-bio"
            src="/assets/misc/author-bio.jpg"
          />
        </div>
        <p>
          I wrote my first book called "Project Exodus" in the fall of 1992 when
          I started college, but soon became busy with school, career, family,
          and kids. I didn't start writing again until about 2007 and ended up
          publishing my first novel ("The Time of Jacob's Trouble") in 2008
          which was later expanded into a well-loved trilogy. And the rest (as
          they say) is history!
        </p>
        <p>
          Aside from writing software and books in my spare time, my other
          interests include spending time with my wife and our dogs, hiking,
          relaxing on the beach, and reading great literature. I've toured
          Israel several times and have also volunteered on an IDF medical base.
          I also enjoy traveling, listening to classical music, studying
          archaeology, history, and politics, and literally devouring audiobooks
          (and of course, I'm on{" "}
          <a
            className="external-link"
            href="https://www.goodreads.com/author/show/2796110.Chris_Hambleton"
            target="_blank"
            rel="noreferrer"
          >
            Goodreads!
          </a>
          ).
        </p>
        <p>
          If you have any questions or comments, message me at{" "}
          <a href="mailto:cwhambletonbooks@gmail.com">
            cwhambletonbooks@gmail.com
          </a>{" "}
          and I will respond as soon as I can. As always, good reviews on Amazon
          and other sites are greatly appreciated. Thank you!
        </p>
      </div>
    </div>
  );
};

export default AuthorBio;
